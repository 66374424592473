import React, { useContext, useEffect, useState } from "react";
import { Link as RouterLink } from "react-router-dom";

import { i18n } from "../translate/i18n";
import { WhatsAppsContext } from "../context/WhatsApp/WhatsAppsContext";
import { SettingsContext } from "../context/Settings/SettingsContext";
import { AuthContext } from "../context/Auth/AuthContext";
import { Can } from "../components/Can";

import packageJson from '../../package.json';

import { green } from "@material-ui/core/colors";

import {
  Badge,
  Divider,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
} from "@material-ui/core";

import {
  VpnKey,
  MenuBook,
  Event,
  LocalOffer,
  PieChart,
  AccountTreeOutlined,
  ContactPhoneOutlined,
  DashboardOutlined,
  LibraryAddOutlined,
  PeopleAltOutlined,
  QuestionAnswerOutlined,
  SettingsOutlined,
  SyncAlt,
  WhatsApp,
  Send,
  Ballot,
  PlaylistAddCheck
} from "@material-ui/icons";


function ListItemLink(props) {
  const { icon, primary, to, className } = props;

  const renderLink = React.useMemo(
    () =>
      React.forwardRef((itemProps, ref) => (
        <RouterLink to={to} ref={ref} {...itemProps} />
      )),
    [to]
  );

  return (
    <li>
      <ListItem button component={renderLink} className={className}>
        {icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
        <ListItemText primary={primary} style={{ marginLeft: -20 }} />
      </ListItem>
    </li>
  );
}

const MainListItems = (props) => {
  const { drawerClose } = props;
  const { whatsApps } = useContext(WhatsAppsContext);
  const { user } = useContext(AuthContext);
  const [connectionWarning, setConnectionWarning] = useState(false);
  const { settings } = useContext(SettingsContext);
  const [modulesCampaigns, setmodulesCampaigns] = useState("disabled");
  const [modulesFinancial, setModulesFinancial] = useState();
  const [modulesStreaming, setModulesStreaming] = useState();

  useEffect(() => {
    const loadsettings = async () => {
      const modulesCampaigns = await settings.find(t => t.key === "modulesCampaigns");
      setmodulesCampaigns(modulesCampaigns?.value);

      const modulesFinancial = await settings.find(t => t.key === "modulesFinancial");
      setModulesFinancial(modulesFinancial?.value);

      const modulesStreaming = await settings.find(t => t.key === "modulesStreaming");
      setModulesStreaming(modulesStreaming?.value);
    }
    loadsettings();
  }, [settings]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(async () => {


      if (whatsApps.length > 0) {
        const offlineWhats = whatsApps.filter((whats) => {
          return (
            whats.status === "qrcode" ||
            whats.status === "PAIRING" ||
            whats.status === "DISCONNECTED" ||
            whats.status === "TIMEOUT" ||
            whats.status === "OPENING"
          );
        });
        if (offlineWhats.length > 0) {
          setConnectionWarning(true);
        } else {
          setConnectionWarning(false);
        }
      }
    }, 2000);
    return () => clearTimeout(delayDebounceFn);
  }, [whatsApps]);

  return (
    <>
      <div onClick={drawerClose}>
        <Divider />

        <Can
          role={user.profile}
          perform="drawer-items:tickets"
          yes={() => (
            <>

              <ListItemLink
                to="/tickets"
                primary={i18n.t("mainDrawer.listItems.tickets")}
                icon={<WhatsApp style={{ color: green[400] }} />}
              />

              <ListItemLink
                to="/contacts"
                primary={i18n.t("mainDrawer.listItems.contacts")}
                icon={<ContactPhoneOutlined style={{ color: green[400] }} />}
              />
              <ListItemLink
                to="/quickAnswers"
                primary={i18n.t("mainDrawer.listItems.quickAnswers")}
                icon={<QuestionAnswerOutlined style={{ color: green[400] }} />}
              />
              <ListItemLink
                to="/schedules"
                primary={i18n.t("mainDrawer.listItems.schedules")}
                icon={<Event style={{ color: green[400] }} />}
              />

              {modulesCampaigns === "enabled" &&
                <ListItemLink
                  to="/Campaigns"
                  primary={i18n.t("mainDrawer.listItems.campaigns")}
                  icon={<Send style={{ color: green[400] }} />}
                />
              }
              <ListItemLink
                to="/tagskanban"
                primary={i18n.t("mainDrawer.listItems.tags")}
                icon={<LocalOffer style={{ color: green[400] }} />}
              />

            </>
          )}
        />
        <Can
          role={user.profile}
          perform="drawer-items:management"
          yes={() => (
            <>
              <Divider />
              <ListSubheader
                color="primary"
              >
                {i18n.t("mainDrawer.listItems.administration")}
              </ListSubheader>
              <ListItemLink
                to="/Dashboard"
                primary="Dashboard"
                icon={<DashboardOutlined style={{ color: green[400] }} />}
              />
              <ListItemLink
                to="/Reports"
                primary={i18n.t("mainDrawer.listItems.reports")}
                icon={<PieChart style={{ color: green[400] }} />}
              />

              <Can
                role={user.profile}
                perform="drawer-items:supervisor"
                yes={() => (
                  <>
                    <ListItemLink
                      to="/users"
                      primary={i18n.t("mainDrawer.listItems.users")}
                      icon={<PeopleAltOutlined style={{ color: green[400] }} />}
                    />
                    <ListItemLink
                      to="/connections"
                      primary={i18n.t("mainDrawer.listItems.connections")}
                      icon={<Badge
                        overlap="rectangular"
                        badgeContent={connectionWarning ? "!" : 0}
                        color="error" >
                        <SyncAlt style={{ color: green[400] }} />
                      </Badge>
                      }
                    />
                    <ListItemLink
                      to="/queues"
                      primary={i18n.t("mainDrawer.listItems.queues")}
                      icon={<AccountTreeOutlined style={{ color: green[400] }} />}
                    />
                    <ListItemLink
                      to="/settings"
                      primary={i18n.t("mainDrawer.listItems.settings")}
                      icon={<SettingsOutlined style={{ color: green[400] }} />}
                    />
                    {modulesFinancial === "enabled" &&
                      <Can
                        role={user.profile}
                        perform="drawer-items:finance"
                        yes={() => (
                          <>
                            <Divider />
                            <ListSubheader
                              color="primary"
                            >
                              {i18n.t("mainDrawer.listItems.financial.title")}
                            </ListSubheader>
                            <ListItemLink
                              to="/plans"
                              primary={i18n.t("mainDrawer.listItems.financial.plans")}
                              icon={<PlaylistAddCheck style={{ color: green[400] }} />}
                            />
                            <ListItemLink
                              to="/subscriptions"
                              primary="Assinaturas"
                              icon={<Ballot style={{ color: green[400] }} />}
                            />
                          </>
                        )}
                      />
                    }

                    <Divider />
                    <ListSubheader style={{ color: green[800] }}>
                      {i18n.t("mainDrawer.listItems.api")}
                    </ListSubheader>

                    <ListItemLink
                      to="/tokens"
                      primary={i18n.t("mainDrawer.listItems.tokens")}
                      icon={<VpnKey style={{ color: green[400] }} />}
                    />

                    <ListItemLink
                      to="/docs"
                      primary={i18n.t("mainDrawer.listItems.docs")}
                      icon={<MenuBook style={{ color: green[400] }} />}
                    />
                  </>
                )}
              />

              <Can
                role={user.profile}
                perform="drawer-items:super"
                yes={() => (
                  <>
                    <Divider />
                    <ListSubheader
                      color="primary"
                    >
                      {i18n.t("mainDrawer.listItems.super.title")}
                    </ListSubheader>
                    <ListItemLink
                      to="/super"
                      primary={i18n.t("mainDrawer.listItems.super.tennants")}
                      icon={<LibraryAddOutlined />}
                    />
                    <ListItemLink
                      to="/msServers"
                      primary="Servidores"
                      icon={<Ballot style={{ color: green[400] }} />}
                    />
                  </>
                )}
              />
            </>
          )}
        />

      </div>
      <Divider />
      <div style={{ color: "grey", display: 'flex', alignItems: 'center', justifyContent: 'center', }}>

        {i18n.t("mainDrawer.listItems.version")}:{packageJson.systemVars.version}
      </div>
    </>
  );
};

export default MainListItems;
