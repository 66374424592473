import React, { useEffect, useState, useContext } from "react";

import { makeStyles } from "@material-ui/core/styles";
import { Typography, IconButton, Paper, Tooltip } from "@material-ui/core";

import {
	DeleteOutline,
	CalendarToday,
	Person,
	LockOpen,
	MonetizationOn,
	Sync,
	Edit,
	AddCircle,
	AddAlert
} from "@material-ui/icons";

import moment from 'moment';

import { i18n } from "../../translate/i18n";
import { MsServersContext } from "../../context/MsServers/MsServersContext";

import api from "../../services/api";
import { toast } from "react-toastify";
import toastError from "../../errors/toastError";
import clsx from "clsx";

import PriceBRL from "../PriceBRL";
import SubscriptionModal from "../SubscriptionModal";
import ConfirmationModal from "../ConfirmationModal";
import ContactDrawerLauncherBoxCards from "../ContactDrawerLauncherBoxCards";
import TableRowSkeleton from "../TableRowSkeleton";




const drawerWidth = 320;

const useStyles = makeStyles(theme => ({
	drawer: {
		width: drawerWidth,
		flexShrink: 0,
		marginLeft: "5px",
	},
	drawerPaper: {
		marginLeft: "5px",
		width: drawerWidth,
		display: "flex",
		borderTop: "1px solid rgba(0, 0, 0, 0.05)",
		borderRight: "1px solid rgba(0, 0, 0, 0.05)",
		borderBottom: "1px solid rgba(0, 0, 0, 0.05)",
		borderTopRightRadius: 4,
		borderBottomRightRadius: 4,
	},
	header: {
		display: "flex",
		borderBottom: "1px solid rgba(0, 0, 0, 0.05)",
		backgroundColor: "rgba(0, 0, 0, 0.05)",
		alignItems: "center",
		padding: theme.spacing(0, 1),
		minHeight: "73px",
		justifyContent: "flex-start",
	},
	content: {
		display: "flex",
		backgroundColor: "rgba(0, 0, 0, 0.05)",
		flexDirection: "column",
		padding: "8px 0px 8px 8px",
		height: "100%",
		overflowY: "scroll",
		...theme.scrollbarStyles,
	},

	contactAvatar: {
		margin: 15,
		width: 160,
		height: 160,
	},

	contactHeader: {
		display: "flex",
		padding: 8,
		flexDirection: "column",
		alignItems: "center",
		justifyContent: "center",
		"& > *": {
			margin: 4,
		},
	},

	contactDetails: {
		marginTop: 8,
		padding: 8,
		display: "flex",
		flexDirection: "column",
	},
	contactExtraInfo: {
		marginTop: 4,
		padding: 6,
	},
	copy: {
		marginLeft: 2,
		cursor: "pointer",
	},
	root: {
		marginBottom: 5,
	},
	title: {
		display: "flex",
		// fontSize: 13,
		// textShadow: '1px 1px 1px black',
		fontWeight: 500,
		color: 'black',
		textAlign: 'center',
	},
	pos: {
		marginBottom: 12,
	},

	infoTag: {
		flex: 1,
		display: "flex",
		color: "gray",
		paddingTop: 3,
		paddingLeft: 5,
		marginRight: 1,
		borderRadius: 3,
	},
	infoTagMany: {
		fontSize: 13,
		borderBottom: "1px solid #e9e9e9",
		display: "flex",
		color: "gray",
		// paddingBotton: 3,
		flexFlow: "row nowrap",
		justifySelf: "center",
		alignSelf: "center",
	},
	infoTagExpire72: {
		color: "#FEDE00",
	},
	infoTagExpire24: {
		color: "#FF8C00",
	},
	infoTagExpired: {
		color: "red",
	},
	infoTagBlue: {
		color: "RoyalBlue",
	},
	infoTagGreen: {
		color: "#00a859",
	},
	infoButton: {
		paddingRight: 5,
		cursor: "pointer",
	},
}));


const ContactDrawerSubscription = ({ contact, modules }) => {
	const classes = useStyles();

	const { msServers } = useContext(MsServersContext);
	const [deletingSubscriptions, setDeletingSubscriptions] = useState(null);
	const [confirmModalOpen, setConfirmModalOpen] = useState(false);
	const [selectedSubscriptions, setSelectedSubscriptions] = useState(null);
	const [subscriptionModalOpen, setSubscriptionModalOpen] = useState(false);
	const [subscriptions, setSubscriptions] = useState(false);
	const [selectedContact, setSelectedContact] = useState(null);
	
	const [extendSubscriptions, setExtendSubscriptions] = useState(null);
	const [confirmExtendModalOpen, setConfirmExtendModalOpen] = useState(false);
	const [loading, setLoading] = useState(false);

	const expireHours = (a) => {
		const b = moment(a).diff(moment(), 'hours')
		return b;
	}

	const msServerName = (msServerId) => {
		const msServersFilter = msServers?.filter(function (e) { return e.id === msServerId; })
		const msServersName = msServersFilter.length > 0 ? msServersFilter[0].name : "";
		return msServersName;
	}


	const handleOpenSubscriptionModal = (contact) => {
		setSelectedContact(contact)
		setSelectedSubscriptions(null);
		setSubscriptionModalOpen(true);
	};

	const handleCloseSubscriptionModal = () => {

		fetchSubscriptions();

		setSelectedSubscriptions(null);
		setSubscriptionModalOpen(false);
	};

	const handleEditSubscriptions = (subscription) => {
		setSelectedSubscriptions(subscription);
		setSubscriptionModalOpen(true);
	};

	const handleSendNotification = async (id) => {

		try {
			await api.get(`/subscription/notificationsContactId/${id}`);
		} catch (err) {
			toastError(err);
		}

	};

	const handleSyncStreaming = async (id, sync) => {
		setLoading("Sincronizando...");
		try {
			await api.get(`/webhooks/syncStreaming/${id}`);

			if (sync) {
				const { data } = await api.get(`/subscription/listByContactId/${contact.id}`);
				setSubscriptions(data);
				setLoading(false);
			}
		} catch (err) {
			setLoading(false);
			toastError(err);
		}

	};

	const fetchSubscriptions = async () => {
		setLoading("Sincronizando...");
		try {
			if (modules.modulesStreaming === "enabled") {
				const { data } = await api.get(`/subscription/listByContactId/${contact.id}`);

				var promises = await data?.map(async item => {
					await handleSyncStreaming(item.id)
				})

				Promise.all(promises).then(async function () {
					const { data } = await api.get(`/subscription/listByContactId/${contact.id}`);
					setSubscriptions(data);
				})
			} else {
				const { data } = await api.get(`/subscription/listByContactId/${contact.id}`);
				setSubscriptions(data);

			}
			setLoading(false);
		} catch (err) {
			setLoading(false);
			toastError(err);
		}

	}

	useEffect(() => {

		fetchSubscriptions();
		// eslint-disable-next-line
	}, [contact]);

	const handleDeleteSubscriptions = async (subscriptionId) => {
		setLoading("Excluindo...");
		try {
			await api.delete(`/subscription/${subscriptionId}`);
			toast.success(i18n.t("global.toasts.deleted"));
			await fetchSubscriptions();

		} catch (err) {
			setLoading(false);
			toastError(err);
		}
		setDeletingSubscriptions(null);
	};

	const handleExtendSubscriptions = async (subscriptionId) => {
		setLoading("Renovando...");
		try {
			await api.get(`/webhooks/updateTVS/${subscriptionId}`);
			toast.success("Atualizado com sucesso");
			await fetchSubscriptions();
		} catch (err) {
			setLoading(false);
			toastError(err);
		}
		setDeletingSubscriptions(null);
	};


	return (

		<Paper square variant="outlined" className={classes.contactDetails}>
			<SubscriptionModal
				open={subscriptionModalOpen}
				onClose={handleCloseSubscriptionModal}
				aria-labelledby="form-dialog-title"
				subscriptionId={selectedSubscriptions && selectedSubscriptions.id}
				contact={selectedContact}
				modules={modules}
			></SubscriptionModal>

			<Typography variant="subtitle1">

				<Typography variant="subtitle1">
					<div style={{ textShadow: '1px 1px 1px black', color: 'black', textAlign: 'left', padding: 5, }}>
						{i18n.t("subscription.title").toUpperCase()}
						{modules.modulesStreaming === "enabled" &&
							<IconButton
								size="small"
								onClick={() => fetchSubscriptions()}
							>
								<Tooltip title={i18n.t("global.buttons.actualize")} >
									<Sync color="primary" />
								</Tooltip>
							</IconButton>
						}
						<IconButton
							size="small"
							onClick={() => handleOpenSubscriptionModal(contact)}
						>
							<Tooltip title={i18n.t("global.buttons.new")} >
								<AddCircle color="primary" />
							</Tooltip>
						</IconButton>
						<IconButton
							size="small"
							onClick={() => handleSendNotification(contact.id)}
						>
							<Tooltip title={i18n.t("subscription.sendAlert")} >
								<AddAlert color="primary" />
							</Tooltip>
						</IconButton>

					</div>
				</Typography>
				{loading && <><TableRowSkeleton columns={2} /> {loading}</>}
				{subscriptions &&
					subscriptions?.map(subs => (
						<div className={classes.root}>
							<div style={{ marginTop: 10, marginBottom: 10, }}></div>
							<ConfirmationModal
								title={
									deletingSubscriptions &&
									`${i18n.t("global.deleteTitle")} ${deletingSubscriptions.serverName}?`
								}
								open={confirmModalOpen}
								onClose={setConfirmModalOpen}
								onConfirm={() => handleDeleteSubscriptions(deletingSubscriptions.subscriptionId)}
							>
								{i18n.t("global.deleteMessage")}
							</ConfirmationModal>

							<ConfirmationModal
								title={
									extendSubscriptions &&
									`Você vai estender 30 dias na conta ${extendSubscriptions.sUser} do servidor ${extendSubscriptions.serverName}?`
								}
								open={confirmExtendModalOpen}
								onClose={setConfirmExtendModalOpen}
								onConfirm={() => handleExtendSubscriptions(extendSubscriptions.subscriptionId)}
							>
								{i18n.t("global.deleteMessage")}
							</ConfirmationModal>

							<Paper variant="outlined" elevation={3} style={{ margin: 5, padding: 5, flex: 1, borderTop: "5px solid gray" }}>
								<Paper className={`${classes.title}`} variant="outlined" elevation={3} style={{ margin: 5, padding: 5, flex: 1 }}>
									{modules.modulesStreaming === "enabled" && msServers &&
										msServerName(subs.msServerId)
									}
									{modules.modulesStreaming !== "enabled" &&
										`#${subs.id}`
									}
									<span color="secondary" >&nbsp;|&nbsp;</span>
									{modules.modulesStreaming === "enabled" &&
										<>
											<IconButton
												size="small"
												onClick={(e) => {
													setConfirmExtendModalOpen(true);
													setExtendSubscriptions({
														sUser: subs.sUser,
														serverName: msServerName(subs.msServerId),
														subscriptionId: subs.id
													})
												}}
											>
												<span style={{ color: "red", fontSize: "0.9em", fontWeight: 600 }} >+30</span>
											</IconButton>


											<IconButton
												size="small"
												onClick={() => handleSyncStreaming(subs.id, true)}
											>
												<Tooltip title={i18n.t("global.buttons.actualize")} >
													<Sync color="primary" />
												</Tooltip>
											</IconButton>
										</>
									}
									<IconButton
										size="small"
										onClick={() => handleEditSubscriptions(subs)}
									>
										<Tooltip title={i18n.t("global.buttons.edit")} >
											<Edit color="primary" />
										</Tooltip>
									</IconButton>
									<IconButton
										size="small"
										onClick={(e) => {
											setConfirmModalOpen(true);
											setDeletingSubscriptions({
												serverName: msServerName(subs.msServerId),
												subscriptionId: subs.id
											})
										}}
									>
										<Tooltip title={i18n.t("global.buttons.delete")} >
											<DeleteOutline color="secondary" />
										</Tooltip>
									</IconButton>

								</Paper>
								<Paper variant="outlined" elevation={3} style={{ margin: 5, padding: 5, flex: 1 }}>
									{modules.modulesStreaming === "enabled" &&
										<>
											{subs.msServerId &&
												<>

													<div className={classes.infoTagMany}>
														<Person className={`${classes.infoButton} ${classes.infoTagBlue}`} /><strong>{i18n.t("global.user")}:</strong>&nbsp;<span>{subs.sUser}</span>
													</div>
													<div className={classes.infoTagMany}>
														<LockOpen className={`${classes.infoButton} ${classes.infoTagGreen}`} /><strong>{i18n.t("global.pass")}:</strong>&nbsp;<span>{subs.sPass}</span>
													</div>
												</>
											}
										</>
									}



									<div
										className={clsx(`${classes.infoTagMany}`, {
											[classes.infoTagGreen]: expireHours(subs.expiresAt) > 120,
											[classes.infoTagExpire72]: expireHours(subs.expiresAt) <= 120,
											[classes.infoTagExpire24]: expireHours(subs.expiresAt) <= 24,
											[classes.infoTagExpired]: expireHours(subs.expiresAt) <= 0,
										})}
									>
										<CalendarToday className={`${classes.infoButton}`} />
										<span><strong>{i18n.t("global.expiresAt")}:&nbsp;{subs.expiresAt ? moment(subs.expiresAt).format('DD/MM/YY HH:mm') : ""}</strong></span>
									</div>
									<div className={classes.infoTagMany}>
										<MonetizationOn className={`${classes.infoButton} ${classes.infoTagBlue}`} /><strong>{i18n.t("global.value")}:</strong>&nbsp;<span> <PriceBRL value={subs.plan.price} /></span>&nbsp;({subs.plan.name})
									</div>
									<div style={{ textAlign: "center", flex: 1, fontSize: "0.7em", marginTop: 5, color: "gray" }}>{i18n.t("global.updatedAt")}: {subs.sLastUpdateSync ? moment(subs.sLastUpdateSync).format('DD/MM/YY HH:mm') : ""}</div>
								</Paper>
								{modules.modulesStreaming === "enabled" &&
									<ContactDrawerLauncherBoxCards subs={subs.id} />
								}

							</Paper>



						</div >

					))}

			</Typography>

		</Paper>

	);
};

export default ContactDrawerSubscription;
