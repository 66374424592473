import React, { useState, useEffect, useReducer } from "react";
import openSocket from "../../services/socket-io";

import {
  Hidden,
  Button,
  IconButton,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  InputAdornment,
  TextField,
} from "@material-ui/core";
import { Edit, DeleteOutline, AddBox, Search } from "@material-ui/icons";

import MainContainer from "../../components/MainContainer";
import MainHeader from "../../components/MainHeader";
import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper";
import Title from "../../components/Title";

import api from "../../services/api";
import { i18n } from "../../translate/i18n";
import TableRowSkeleton from "../../components/TableRowSkeleton";
import MsServerModal from "../../components/MsServerModal";
import ConfirmationModal from "../../components/ConfirmationModal";
import { toast } from "react-toastify";
import toastError from "../../errors/toastError";

const reducer = (state, action) => {
  if (action.type === "LOAD_PLANS") {
    const msServers = action.payload;
    const newMsServers = [];

    msServers.forEach((msServer) => {
      const msServerIndex = state.findIndex((q) => q.id === msServer.id);
      if (msServerIndex !== -1) {
        state[msServerIndex] = msServer;
      } else {
        newMsServers.push(msServer);
      }
    });

    return [...state, ...newMsServers];
  }

  if (action.type === "UPDATE_PLANS") {
    const msServer = action.payload;
    const msServerIndex = state.findIndex((q) => q.id === msServer.id);

    if (msServerIndex !== -1) {
      state[msServerIndex] = msServer;
      return [...state];
    } else {
      return [msServer, ...state];
    }
  }

  if (action.type === "DELETE_PLANS") {
    const msServerId = action.payload;

    const msServerIndex = state.findIndex((q) => q.id === msServerId);
    if (msServerIndex !== -1) {
      state.splice(msServerIndex, 1);
    }
    return [...state];
  }

  if (action.type === "RESET") {
    return [];
  }
};

const useStyles = makeStyles((theme) => ({
  mainPaper: {
    flex: 1,
    padding: theme.spacing(1),
    overflowY: "scroll",
    ...theme.scrollbarStyles,
  },
}));

const MsServers = () => {
  const classes = useStyles();

  const [loading, setLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [searchParam, setSearchParam] = useState("");
  const [msServers, dispatch] = useReducer(reducer, []);
  const [selectedMsServers, setSelectedMsServers] = useState(null);
  const [msServersModalOpen, setMsServerModalOpen] = useState(false);
  const [deletingMsServers, setDeletingMsServers] = useState(null);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [hasMore, setHasMore] = useState(false);

  useEffect(() => {
    dispatch({ type: "RESET" });
    setPageNumber(1);
  }, [searchParam]);

  useEffect(() => {
    setLoading(true);
    const delayDebounceFn = setTimeout(() => {
      const fetchMsServers = async () => {
        try {
          const { data } = await api.get("/msServers/", { 
            params: { searchParam, pageNumber },
          });
          dispatch({ type: "LOAD_PLANS", payload: data.msServers });
          setHasMore(data.hasMore);
          setLoading(false);
        } catch (err) {
          toastError(err);
        }
      };
      fetchMsServers();
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [searchParam, pageNumber]);

  useEffect(() => {
    const socket = openSocket();

    socket.on("msServer", (data) => {
      if (data.action === "update" || data.action === "create") {
        dispatch({ type: "UPDATE_PLANS", payload: data.msServer });
      }

      if (data.action === "delete") {
        dispatch({
          type: "DELETE_PLANS",
          payload: +data.msServerId,
        });
      }
    });

    return () => {
      socket.disconnect();
    };
  }, []);

  const handleSearch = (event) => {
    setSearchParam(event.target.value.toLowerCase());
  };

  const handleOpenMsServerModal = () => {
    setSelectedMsServers(null);
    setMsServerModalOpen(true);
  };

  const handleCloseMsServerModal = () => {
    setSelectedMsServers(null);
    setMsServerModalOpen(false);
  };

  const handleEditMsServers = (msServer) => {
    setSelectedMsServers(msServer);
    setMsServerModalOpen(true);
  };

  const handleDeleteMsServers = async (msServerId) => {
    try {
      await api.delete(`/msServers/${msServerId}`);
      toast.success(i18n.t("global.deleted"));
    } catch (err) {
      toastError(err);
    }
    setDeletingMsServers(null);
    setSearchParam("");
    setPageNumber(1);
  };

  const loadMore = () => {
    setPageNumber((prevState) => prevState + 1);
  };

  const handleScroll = (e) => {
    if (!hasMore || loading) return;
    const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
    if (scrollHeight - (scrollTop + 100) < clientHeight) {
      loadMore();
    }
  };

  return (
    <MainContainer>
      <ConfirmationModal
        title={
          deletingMsServers &&
          `${i18n.t("global.deleteTitle")} ${
            deletingMsServers.shortcut
          }?`
        }
        open={confirmModalOpen}
        onClose={setConfirmModalOpen}
        onConfirm={() => handleDeleteMsServers(deletingMsServers.id)}
      >
        {i18n.t("global.deleteMessage")}
      </ConfirmationModal>
      <MsServerModal
        open={msServersModalOpen}
        onClose={handleCloseMsServerModal}
        aria-labelledby="form-dialog-title"
        msServerId={selectedMsServers && selectedMsServers.id}
      ></MsServerModal>
      <MainHeader>
        <Title>{i18n.t("msServer.title")}</Title>
        <MainHeaderButtonsWrapper> 
        <Hidden mdDown>
          <TextField
            placeholder={i18n.t("global.searchPlaceholder")}
            type="search"
            value={searchParam}
            onChange={handleSearch}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search style={{ color: "gray" }} />
                </InputAdornment>
              ),
            }}
          />
          </Hidden>
          <Button
            variant="contained"
            color="primary"
            onClick={handleOpenMsServerModal}
          >
            <AddBox /> <Hidden mdDown>{i18n.t("global.buttons.add")}</Hidden>
          </Button>
        </MainHeaderButtonsWrapper>
      </MainHeader>
      <Hidden lgUp>
          <TextField
            placeholder={i18n.t("global.searchPlaceholder")}
            type="search"
            value={searchParam}
            onChange={handleSearch}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search style={{ color: "gray" }} />
                </InputAdornment>
              ),
            }}
          />
          <br />
          </Hidden>
      <Paper
        className={classes.mainPaper}
        variant="outlined"
        onScroll={handleScroll}
      >
        <Table size="small">
          <TableHead>
          <TableRow>
              <TableCell align="center">
                {i18n.t("global.name")}
              </TableCell>
              <TableCell align="center">
                {i18n.t("global.description")}
              </TableCell>
              <TableCell align="center">
                {i18n.t("msServer.valueCredit")}
              </TableCell>
              <TableCell align="center">
                {i18n.t("msServer.typeServer")}
              </TableCell>
              <TableCell align="center">
                DNS
              </TableCell> 
              <TableCell align="center">
                Status
              </TableCell>                            
              <TableCell align="center">
                {i18n.t("global.actions")}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <>
            {msServers.map((msServer) => (
                <TableRow key={msServer.id}>
                  <TableCell align="center">{msServer.name}</TableCell>
                  <TableCell align="center">{msServer.description}</TableCell>
                  <TableCell align="center">{msServer.valueCredit.replace(".", ",")}</TableCell>
                  <TableCell align="center">{msServer.typeServer}</TableCell>
                  <TableCell align="center">{msServer.dns}</TableCell>
                  <TableCell align="center">{msServer.status ? "✅" : "❌"}</TableCell>
                  <TableCell align="center">
                    <IconButton
                      size="small"
                      onClick={() => handleEditMsServers(msServer)}
                    >
                      <Edit />
                    </IconButton>

                    <IconButton
                      size="small"
                      onClick={(e) => {
                        setConfirmModalOpen(true);
                        setDeletingMsServers(msServer);
                      }}
                    >
                      <DeleteOutline />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
              {loading && <TableRowSkeleton columns={4} />}
            </>
          </TableBody>
        </Table>
      </Paper>
    </MainContainer>
  );
};

export default MsServers;
