import React, { useContext, useEffect, useRef, useState } from "react";

import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import SearchIcon from "@material-ui/icons/Search";
import InputBase from "@material-ui/core/InputBase";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Badge from "@material-ui/core/Badge";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import FilterListIcon from '@material-ui/icons/FilterList';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import NewTicketModal from "../NewTicketModal";
import TicketsList from "../TicketsList";
import TabPanel from "../TabPanel";

import { i18n } from "../../translate/i18n";
import { AuthContext } from "../../context/Auth/AuthContext";
import { Can } from "../Can";
import TicketsQueueSelect from "../TicketsQueueSelect";
import { Button } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  ticketsWrapper: {
    position: "relative",
    display: "flex",
    height: "100%",
    flexDirection: "column",
    verflow: "hidden",
    borderRadius: "10px",
    backgroundColor: "#FFFFFF",
  },

  tabsHeader: {
    flex: "none",
    backgroundColor: "#FFFFFF",
  },

  settingsIcon: {
    alignSelf: "center",
    marginLeft: "auto",
    padding: 8,
  },

  tab: {

  },
  tabName: {
    right: 0,
    minWidth: 120,
    width: 120,
    padding: 15,
    fontSize: "90%",
  },
  tabHidden: {
    width: 0,
    maxWidth: 0,
  },
  ticketOptionsBox: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    background: "#FFFFFF",
    padding: theme.spacing(1),
  },

  serachInputWrapper: {
    flex: 1,
    background: "rgba(0, 0, 0, 0.05)",
    display: "flex",
    borderRadius: 10,
    padding: 4,
    marginRight: theme.spacing(1),
  },
  tabIcon: {
    borderRadius: 5,
    height: "36px",
    width: "36px",
    padding: "5px",
    cursor: "pointer",
    color: "grey",
    alignSelf: "center",
    background: "rgba(0, 0, 0, 0.05)",
    marginLeft: 3,
    marginRight: 3,
  },
  searchIcon: {
    color: "grey",
    marginLeft: 6,
    marginRight: 6,
    alignSelf: "center",
  },

  searchInput: {
    flex: 1,
    border: "none",
    borderRadius: 10,
  },

  show: {
    display: "block",
  },
  hide: {
    display: "none !important",
  },
}));

const TicketsManager = () => {
  const classes = useStyles();

  const [searchParam, setSearchParam] = useState("");
  const [tab, setTab] = useState("open");
  const [tabOpen, setTabOpen] = useState("open");
  const [newTicketModalOpen, setNewTicketModalOpen] = useState(false);
  const [showAllTickets, setShowAllTickets] = useState(false);
  const searchInputRef = useRef();
  const { user } = useContext(AuthContext);

  const [openCount, setOpenCount] = useState(0);
  const [pendingCount, setPendingCount] = useState(0);

  const userQueueIds = user.queues.map((q) => q.id);
  const [selectedQueueIds, setSelectedQueueIds] = useState(userQueueIds || []);

	const [openOptions, setOpenOptions] = useState(false);

	
	const handleOpenOptions = () => {
		setOpenOptions(true);
	};

	const handleCloseOptions = () => {
		setOpenOptions(false);
	};

  useEffect(() => {
    if (user.profile.toUpperCase() === "ADMIN" || user.profile.toUpperCase() === "SUPER") { 
      setShowAllTickets(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let searchTimeout;

  const handleSearch = (e) => {
    const searchedTerm = e.target.value.toLowerCase();

    clearTimeout(searchTimeout);
    setSearchParam(searchedTerm);

    if (searchedTerm === "") {
      setTab("open");
      return;
    }

    searchTimeout = setTimeout(() => {
      setSearchParam(searchedTerm);
    }, 800);
  };

  const handleChangeTabOpen = (e, newValue) => {
    setTabOpen(newValue);
  };

  const handleChangeTabOpenChild = newValue => {
    setTabOpen(newValue);
  };


  const applyPanelStyle = (status) => {
    if (tabOpen !== status) {
      return { width: 0, height: 0 };
    }
  };

  return (
    <Paper elevation={0} variant="outlined" className={classes.ticketsWrapper}>
      <NewTicketModal
        modalOpen={newTicketModalOpen}
        onClose={(e) => setNewTicketModalOpen(false)}
      />
      <Paper square elevation={0} className={classes.ticketOptionsBox}>
        <div className={classes.serachInputWrapper}>
          <SearchIcon className={classes.searchIcon} />
          <InputBase
            className={classes.searchInput}
            inputRef={searchInputRef}
            placeholder={i18n.t("tickets.search.placeholder")}
            type="search"
            onChange={handleSearch}
          />
        </div>
        <Dialog open={openOptions} onClose={handleCloseOptions}>
          {/* <DialogTitle>Fill the form</DialogTitle> */}
          <DialogContent>
          <Can
              role={user.profile}
              perform="tickets-manager:showall"
              yes={() => (
                <FormControlLabel
                  label={i18n.t("tickets.buttons.showAll")}
                  labelPlacement="start"
                  control={
                    <Switch
                      size="small"
                      checked={showAllTickets}
                      onChange={() =>
                        setShowAllTickets((prevState) => !prevState)
                      }
                      name="showAllTickets"
                      color="primary"
                    />
                  }
                />
              )}
            />
            <TicketsQueueSelect
              style={{ marginLeft: 6 }}
              selectedQueueIds={selectedQueueIds}
              userQueues={user?.queues}
              onChange={(values) => setSelectedQueueIds(values)}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseOptions} color="primary">
              Ok
            </Button>
          </DialogActions>
        </Dialog>
        
        <FilterListIcon
          className={classes.tabIcon}
          onClick={handleOpenOptions}
        />
        <AddCircleIcon
          onClick={() => setNewTicketModalOpen(true)}
          alt={i18n.t("ticketsManager.buttons.newTicket")}
          className={classes.tabIcon} 
        />
      </Paper>
      {!searchParam ? (
        <>
          <TabPanel value={tab} name="open" className={classes.ticketsWrapper}>
            <Tabs
              value={tabOpen}
              onChange={handleChangeTabOpen}
              indicatorColor="primary"
              textColor="primary"
              variant="fullWidth"
            >
              <Tab
                className={classes.tabName}
                label={
                  <Badge
                    overlap="rectangular"
                    className={classes.badge}
                    badgeContent={openCount}
                    color="primary"
                  >
                    {i18n.t("ticketsList.assignedHeader")}
                  </Badge>
                }
                value={"open"}
              />
              <Tab
                className={classes.tabName}
                label={
                  <Badge
                    overlap="rectangular"
                    className={classes.badge}
                    badgeContent={pendingCount}
                    color="secondary"
                  >
                    {i18n.t("ticketsList.pendingHeader")}
                  </Badge>
                }
                value={"pending"}
              />
              <Tab
                className={classes.tabName}
                label={
                  i18n.t("tickets.tabs.closed.title")
                }
                value={"closed"}
              />
            </Tabs>
            <Paper className={classes.ticketsWrapper}>
              <TicketsList
                onSetTabOpen={handleChangeTabOpenChild}
                status="open"
                showAll={showAllTickets}
                selectedQueueIds={selectedQueueIds}
                updateCount={(val) => setOpenCount(val)}
                style={applyPanelStyle("open")}
              />
              <TicketsList
                onSetTabOpen={handleChangeTabOpenChild}
                status="pending"
                selectedQueueIds={selectedQueueIds}
                updateCount={(val) => setPendingCount(val)}
                style={applyPanelStyle("pending")}
              />
              <TicketsList
                onSetTabOpen={handleChangeTabOpenChild}
                status="closed"
                showAll={true}
                selectedQueueIds={selectedQueueIds}
                style={applyPanelStyle("closed")}
              />
            </Paper>
          </TabPanel>
        </>
      ) : (
        <Paper value={tab} name="search" className={classes.ticketsWrapper}>
          <TicketsList
            onSetTabOpen={handleChangeTabOpenChild}
            searchParam={searchParam}
            showAll={true}
            selectedQueueIds={selectedQueueIds}
          />
        </Paper>
      )}
    </Paper>
  );
};

export default TicketsManager;
